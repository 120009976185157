.login .logo {
  position: absolute;
  color: #ff614d;
  font-size: 45px;
  font-weight: 700;
  top: 20px;
  left: 30px;
  z-index: 2;
}

.login .loginBackground {
  background: url(https://settle.dk/images/Hero_foto.webp);
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(1);
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1;
}

.warning {
  color: red;
}

.loginContainer {
  z-index: 2;
  position: relative;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: nowrap;
  align-items: center;
    height: 100vh;
}

.buttonContainer {
  display: flex;
    justify-content: space-between;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 30px;
}

.loginButton {
  display: inline-flex;
  width: 150px;
  height: 35px;
  background-color: var(--primary-color) !important;
  color: var(--text-color) !important;
}


.login .loginContainer h1 {
  color: #58595e;
  font-size: 31px;
  line-height: 54px;
  margin: 0px;
}


.login .loginContainer .SubHeadingContainer {
  padding-top: 30px;
  max-width: 680px;
  margin-right: 40px
}

.login .loginContainer .HeadingContainer {
  max-width: 600px;
}

.login .loginContainer .loginBoxContainer {
  background: #fff;
  border-radius: 10px;
  width: 600px;
}

.login .loginContainer .loginBoxContainer .textContainer {
  padding: 30px;
}

