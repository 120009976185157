:root {
  --brandColor: #fa320f;
  --mainColor: #bcb6af;
  --mainTextColor: white;
}
@font-face {
  font-family: Lato;
  font-weight: 100;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url(/public/fonts/lato-hairline.woff);
}

@font-face {
  font-family: Lato;
  font-weight: 300;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url(/public/fonts/lato-light.woff);
}

@font-face {
  font-family: Lato;
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url(/public/fonts/lato-normal.woff);
}

@font-face {
  font-family: Lato;
  font-weight: 800;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url(/public/fonts/lato-heavy.woff);
}

body {
/*  background: var(--primary-color); */
background: #f7f5f4;
}

* {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
    height: 100%;
    margin: 0;
}

body {
  font-family: Lato;
  position: relative;
  top: 0px;
  height: 100%;
  margin: 0px;
  -webkit-font-smoothing: antialiased;
}

.App-header {
  background-color: rgb(247, 247, 247);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

p {
  font-size: 14px;
}

.centerVertical {
  text-align: left;
}
.centerText, .centerVertical {
  display: table-cell;
  height: 100vh;
  vertical-align: middle;
  width: 100vw;
}

.Mui-active {
 /* color: var(--primary-color) !important; */
}

.Mui-checked {
  color: var(--primary-color) !important;
}

.Mui-completed {
  color: var(--primary-color) !important;
}

.TopAreaMain {
  -webkit-box-sizing: unset;
  position: fixed;
  z-index: 9999;
  top: 0px;
  left: 0px;
  right: 0px;
  color: #fff;
}

.MainArea {
  padding-top: 120px;
}

.fileuploadContainer {
  height: 350px;
  width: 100%;
  border: 1px solid #c8c8c8;
  overflow-y: auto;
  overflow-x: hidden;
}


#file-input {
  position: relative;
  height: 100%;
  width: 100%;

  cursor: pointer;
  opacity: 0.0;
  filter: alpha(opacity=0);
}


.uploadTextContainer {
  position: absolute;
  top: 188px;
  width: 93%;
  pointer-events: none;
}

.uploadText {
  width: 100%;
  text-align: center;
}

.uploadItem {
  height: 140px;
  width: 140px;
  object-fit: contain;

}

.uploadItemContainer {
  margin: 5px;
  height: 160px;
  width: 140px;
  float: left;
  position: relative;
  background: #f1f1f1;
  font-size: 10px;
}

.uploadItemFilename {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 127px;
  white-space: nowrap;
  padding-left: 10px;
  padding-top: 2px;
}

.uploadIcon {
  text-align: center;
  width: 100%;
  font-size: 80px;
}

.greenCheckmark {
  background: url(/public/checkmark.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 30px;
  left: 30px;
  height: 80px;
  width: 80px;
  filter: drop-shadow(0px 0px 10px black) grayscale(1) contrast(3);
}


/*  upload animation */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 6px solid #0c0c0c;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}