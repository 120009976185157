.mainMenuLogo {
  
  color: #ff614d;
  font-size: 25px;
  font-weight: 700;
  max-width: 200px;
  width: 100%;
  height: 80px;
  position: relative;
  top:25px;
  left:65px;
  }
  