
.orderInfo .contentContainer {
  border: 0px solid var(--primary-color);
  padding: 20px;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

.orderInfo .Heading {
  text-align: center;
  font: normal normal bold 35px / 46px Roboto;
  margin-bottom: 40px;
  color: #58595e
}

.orderInfo .item {
  display: flex;
  padding-bottom: 15px;
  align-items: center;
}

.orderInfo .itemLabel {
    font-weight: bold;
    width: 140px;
}

.orderInfo .itemDataProductContainer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.orderInfo .itemDataProduct {
    display: block;
}

.orderInfo .note {
  width: 100%;
  height: 100%;
}

.orderInfo .itemData {
  width: 50%;
}

.orderInfo .mediaItem {
  width: 200px;
    height: 134px;

    cursor: pointer;
    background: #dfdfdf;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
}



.orderInfo .playIcon {
  position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    color: #ffffffba;
    filter: drop-shadow(2px 4px 6px black);
    font-size: 59px;
}

.orderInfo .mediaItem img {
  
  width: 100%;
  height: 100%;
  object-fit: contain;
  
}

.orderInfo .mediaContainer {
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.orderInfo .mediaDescription {
  width: 100%;
    height: 20px;
    background: black;
    color: white;
    opacity: 0.7;
    text-align: center;
    font-size: 12px;
    padding-top: 3px;
    position: relative;
    top: -30px;
}